<template>
    <div class="row">
        <div class="full-load" v-if="loading">
            <h1>
                Please Wait... <br />
                Connecting to QuickBooks
            </h1>
        </div>
        <div class="col-md-8 offset-2">
            <div class="card form-body">
                <div class="card-body">
                    <legend>
                        <h5>QuickBooks</h5>
                    </legend>
                    <hr />
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Secret Key <span class="text-danger">*</span></label>
                                <input type="text" name="" class="form-control" id="" v-model="details.clientSecret"
                                    :class="errors['client_secret_key'] ? 'border border-danger' : ''" />
                                <span v-if="errors['client_secret_key']" class="text-danger">{{
                                    errors["client_secret_key"][0]
                                }}</span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Client ID <span class="text-danger">*</span></label>
                                <input type="text" Last Name="" class="form-control" id="" v-model="details.clientId"
                                    :class="errors['client_id'] ? 'border border-danger' : ''" />
                                <span v-if="errors['client_id']" class="text-danger">{{
                                    errors["client_id"][0]
                                }}</span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Environment <span class="text-danger">*</span></label>
                                <select class="form-control" v-model="details.environment">
                                    <option value="sandbox">Sandbox</option>
                                    <option value="production">Production</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" @click="onConnect" style="float: right">
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { FETCH_QUICKBOOK_DATA, SEND_APOSTILLE_EMAIL, UPDATE_QUICKBOOK_CREDENTIAL } from "../../../../core/services/store/actions.type";

const OAuthClient = require('intuit-oauth')
export default {
    data() {
        return {
            breadCrumbs: [
                {
                    id: 1,
                    title: "Dashboard",
                    route: "client.dashboard"
                },
                {
                    id: 2,
                    title: "Setting",
                    route: ""
                },
                {
                    id: 3,
                    title: "QuickBooks",
                    route: ""
                }
            ],
            details: {
                clientId: '',
                clientSecret: '',
                environment: 'sandbox',
                redirectUri: '',
            },
            errors: [],
            loading: false,
        }
    },
    created() {
        this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
        var client_slug = this.$route.params.client_slug;
        this.details.redirectUri = window.location.origin + '/' + client_slug + '/quickbooks-setup';
    },
    mounted() {
        var data = JSON.parse(window.localStorage.getItem('qucikbook_details'));
        if (data) {
            this.details.clientId = data.clientId;
            this.details.clientSecret = data.clientSecret;
            this.details.environment = data.environment;

            var code = this.$route.query.code;
            var state = this.$route.query.state;
            var realmId = this.$route.query.realmId;

            if (code && state && realmId) {
                this.onDataSet(code, state, realmId);
                const url = new URL(window.location.href);
                url.search = '';
                window.history.replaceState({}, '', url.toString());
            } else {
                window.localStorage.removeItem('qucikbook_details');
                this.fetchData();
            }
        } else {
            this.fetchData();
        }
    },
    methods: {
        onConnect() {
            if (this.details.clientId && this.details.clientSecret && this.details.environment) {
                window.localStorage.setItem('qucikbook_details', JSON.stringify(this.details));
                const oauthClient = new OAuthClient(this.details);
                const authUri = oauthClient.authorizeUri({
                    scope: [OAuthClient.scopes.Accounting, OAuthClient.scopes.OpenId],
                    state: 'testState',
                });
                window.location.href = authUri;
            }
        },
        onDataSet(code, state, realmId) {
            this.loading = true;
            window.localStorage.removeItem('qucikbook_details');
            var data = {
                client_secret_key: this.details.clientSecret,
                client_id: this.details.clientId,
                realm_id: realmId,
                auth_code: code,
                state: state,
                environment: this.details.environment,
                redirect_uri: this.details.redirectUri
            };
            this.$store.dispatch(UPDATE_QUICKBOOK_CREDENTIAL, data)
            .then((res) => {
                this.loading = false;
                this.$toastr.s("Connected Successfully!");
            })
            .catch((err) => {
                this.loading = false;
                this.$toastr.e("Invalid Detail!");
            })
            console.log('test');
        },
        fetchData() {
            this.$store.dispatch(FETCH_QUICKBOOK_DATA)
                .then((data) => {
                    this.details.clientId = data.quickbook_client_id;
                    this.details.clientSecret = data.quickbook_client_secret;
                    this.details.environment = data.environment;
                })
                .catch((err) => {
                    // console.log(err.error);
                })
        }
    }
}
</script>